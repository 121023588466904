<template>

  <!-- ObjectPageHeader -->
  <div class="objectPageHeader">

    <!-- Object Name & Path -->
    <div class="row">
      <div class="flex xs12 md10">
        <h2 class="display-2 text--capitalize">{{ objectName }}</h2>
        <p class="text--secondary">turtlecreek-2019/database/firestore/data/{{objectName}}</p>
      </div>

      <!-- Refresh Page data -->
      <div class="flex xs12 md2">
        <va-button
          v-on:click="refreshData"
          icon="iconicstroke iconicstroke-reload"
          color="info">Refresh
        </va-button>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'objectPageHeader',
  props: ['objectName'],
  methods: {
    refreshData: function () {
      this.$emit('refreshData', this.objectName)
    },
  },
}
</script>

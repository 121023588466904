<template>
  <div class="products">

    <!-- Header -->
    <ObjectPageHeader
      v-bind:objectName="objectName"
      v-on:refreshData="refreshData($event)">
    </ObjectPageHeader>

    <!-- Info Tiles -->
    <div class="row">
      <div
        v-for="(info, idx) in infoTiles"
        v-on:click="filterByType(info.type)"
        :key="idx"
        class="flex xs12 md2"
      >
        <va-card class="mb-4" :color="info.color">
          <p class="display-2 mb-0" style="color: white;">{{ info.value }}</p>
          <p> {{ info.text }}</p>
        </va-card>
      </div>

      <div class="flex xs12 md4">
        <va-card class="mb-4" color="orange">
          <p v-on:click="createProduct" class="display-2 mb-0" style="color: white;">Create New Product</p>
          <p v-on:click="createProduct">Need more Products? <u>Click here</u> to start</p>
        </va-card>
      </div>
    </div>

    <!-- Data table headers -->
    <va-card v-if="!showProduct" title="All Products">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="Search by Product Name or ID"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>

      <!-- Data Table Contents -->
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        hoverable
        no-data-label="Nothing to show right now :("
        :loading="loading"
      >

        <template slot="images" slot-scope="props">
          <img
            v-bind="productImage"
            :src="props.rowData.images[0]"
            class="img-fluid"
            alt="Image of Turtle Creek Brand Logo Blue and White"
          />
        </template>

        <template slot="status" slot-scope="props">
          <va-icon
            v-if="props.rowData.status"
            color="success"
            name="fa fa-check-circle"
          />

          <va-icon
            v-else
            color="gray"
            name="fa fa-times-circle"
          />
        </template>

        <template slot="actions" slot-scope="props">
          <va-popover :message="`View Product ${props.rowData.productID} Live on site`" placement="left">
            <va-button flat small color="dark" icon="fa fa-eye" v-on:click="openProduct(props.rowData, props.rowIndex)"/>
          </va-popover>

          <va-popover :message="`Edit Product ${props.rowData.productID}`" placement="left">
            <va-button flat small color="info" icon="fa fa-pencil" v-on:click="editProduct(props.rowData)"/>
          </va-popover>

          <va-popover :message="`${$t('tables.delete')} Product ${props.rowData.productID}`" placement="left">
            <va-button flat small color="danger" icon="fa fa-trash" v-on:click="deleteProduct(props.rowData, props.rowIndex)"/>
          </va-popover>
        </template>

      </va-data-table>
    </va-card>

    <ProductPage v-else
      v-bind:mode="mode"
      v-bind:product="selectedProduct"
      v-on:saveProduct="saveProduct()"
      v-on:featureProduct="featureProduct($event)"
      v-on:goBack="goBack()">
    </ProductPage>

  </div>
</template>

<script>
import { debounce } from 'lodash'
import ProductPage from '@/components_tc/products/ProductPage.vue'
import ObjectPageHeader from '@/components_tc/utilities/ObjectPageHeader.vue'
import { productCollection } from '@/firebaseConfig.js'

export default {
  name: 'products',
  data () {
    return {
      term: null,
      mode: '',
      products: [],
      perPage: '25',
      loading: false,
      objectName: '',
      showProduct: false,
      activeItems: [],
      featuredItems: [],
      outOfStockItems: [],
      selectedProduct: {},
      perPageOptions: ['10', '25', '50', '100'],
      productImage: { width: 100, height: 100, class: 'm1' },
      infoTiles: [{
        color: 'secondary',
        value: '0',
        text: 'Total Products',
        type: 'total',
      }, {
        color: 'info',
        value: '0',
        text: 'Out of Stock',
        type: 'empty',
      }, {
        color: 'warning',
        value: '0',
        text: 'Featured Products',
        type: 'featured',
      }],
    }
  },
  components: {
    ObjectPageHeader,
    ProductPage,
  },
  computed: {
    Products: function () {
      return this.$store.state.products
    },
    fields () {
      return [{
        name: '__slot:images',
        title: this.$t('Product'),
        width: '15%',
      }, {
        name: 'title',
        title: this.$t('Name'),
        sortField: 'title',
        width: '28%',
      }, {
        name: 'productID',
        title: this.$t('Product ID'),
        sortField: 'productID',
        width: '22%',
      }, {
        name: 'availability',
        title: this.$t('Availability'),
        sortField: 'availability',
        width: '10%',
      }, {
        name: '__slot:status',
        title: this.$t('Featured'),
        sortField: 'status',
        width: '5%',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.activeItems
      }

      return this.activeItems.filter(item => {
        return item.title.toLowerCase().startsWith(this.term.toLowerCase()) || item.productID.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),

    refreshData: function (event) {
      this.getProductData(event)
    },

    goBack: function () {
      this.showProduct = false
    },

    filterByType: function (type) {
      switch (type) {
        case 'total': this.activeItems = this.products; break
        case 'empty': this.activeItems = this.outOfStockItems; break
        case 'featured': this.activeItems = this.featuredItems; break
        default: this.activeItems = this.products
      }
    },

    saveProduct () {
      this.showToast(
        'Product Saved',
        {
          icon: 'fa-check-circle',
          position: 'bottom-right',
          duration: 2500,
          fullWidth: false,
        },
      )
    },

    editProduct: function (productData) {
      this.mode = 'edit'
      this.selectedProduct = productData
      this.showProduct = true
    },

    openProduct: function (productData) {
      window.open(
        'https://www.turtlecreek.ie/product/' +
        productData.productID,
        '_blank',
      )
    },

    featureProduct: function (product) {
      const uniqueFeaturedItems = new Set(this.featuredItems)
      uniqueFeaturedItems.add(product)
      this.featuredItems = Array.from(uniqueFeaturedItems)
      this.featuredItems.sort((a, b) => (a.title > b.title) ? 1 : -1)
      this.updateInfoTiles(this.products, this.outOfStockItems, this.featuredItems)
    },

    createProduct: function () {
      this.mode = 'create'
      this.selectedProduct = {
        availability: '',
        brand: '',
        careSheet: '',
        category: '',
        description: '',
        productType: '',
        images: [],
        imagesAltText: [],
        liveShippingPremium: 0.00,
        liveShipping: false,
        options: [],
        price: {},
        productID: '',
        quantity: {},
        status: false,
        title: '',
        warrenty: null,
      }
      this.showProduct = true
    },

    deleteProduct: function (productData, productIndex) {
      const _this = this
      this.loading = true

      productCollection
        .doc(productData.productID)
        .delete()
        .then(function () {
          _this.deleteFromTable(productData, productIndex)
          _this.showToast(
            'Product Deleted',
            {
              icon: 'fa-trash',
              position: 'bottom-right',
              duration: 2500,
              fullWidth: false,
            },
          )
        })
        .catch(err => {
          alert(err.message)
          _this.loading = false
        })
    },

    deleteFromTable: function (productData, productIndex) {
      let realIndex
      const productIndexRev = (this.products.length - 1) - productIndex

      if (productData.productID === this.products[productIndex].productID) {
        realIndex = productIndex
      } else if (productData.productID === this.products[productIndexRev].productID) {
        realIndex = productIndexRev
      }

      this.products.splice(realIndex, 1)
      this.$store.commit('setProductData', this.products)
      this.loading = false
    },

    getProductData: function (event) {
      var _this = this
      this.loading = true

      if (this.Products.productData === undefined || this.Products.productData.length === 0 || event != null) {
        productCollection
          .get()
          .then(function (dataSnapshot) {
            const jsonProducts = []
            const outOfStockJson = []
            const featuredItems = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()

              if (Object.values(itemData.quantity).reduce((a, b) => a + b, 0) <= 0) {
                itemData.availability = 'Out of Stock'
                outOfStockJson.push(itemData)
              }
              if (itemData.status) {
                featuredItems.push(itemData)
              }
              jsonProducts.push(itemData)
            })

            _this.outOfStockItems = outOfStockJson.sort((a, b) => (a.title > b.title) ? 1 : -1)
            _this.products = jsonProducts.sort((a, b) => (a.title > b.title) ? 1 : -1)
            _this.featuredItems = featuredItems.sort((a, b) => (a.title > b.title) ? 1 : -1)
            const productStoreCollection = {
              productData: _this.products,
              outOfStockItems: _this.outOfStockItems,
              featuredItems: _this.featuredItems,
            }

            _this.$store.commit('setProducts', productStoreCollection)
            _this.updateInfoTiles(_this.products, _this.outOfStockItems, _this.featuredItems)

            _this.activeItems = _this.products
            _this.loading = false
          })
          .catch(err => {
            alert(err.message)
          })
      } else {
        this.products = this.Products.productData
        this.outOfStockItems = this.Products.outOfStockItems
        this.featuredItems = this.Products.featuredItems
        this.updateInfoTiles(this.products, this.outOfStockItems, this.featuredItems)

        this.activeItems = this.products
        this.loading = false
      }
    },

    updateInfoTiles: function (totalProducts, outOfStockItems, featuredItems) {
      this.infoTiles[0].value = totalProducts.length
      this.infoTiles[1].value = outOfStockItems.length
      this.infoTiles[2].value = featuredItems.length
    },

  },
  mounted () {
    this.objectName = this.$options.name
    this.getProductData(null)
  },
}

</script>
